.subtitle_b{
  font-style: normal;
  font-weight: bold !important;
  font-size: 18px;
  line-height: 24px;  
  letter-spacing: 0.5px; 
  color: #223263;  
}
.form_button{
  margin: 0 4px 0;
}
.logo{
  margin: 0 0 40px;
}