.container {
  display: flex;
  flex: 1;
}

.job {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding: 30px 0 20px;
  flex: 1;
  align-items: center;
}
