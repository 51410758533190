.container {
  max-width: 60rem;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
}

.progress {
  margin-left: 16px;
}