.container {
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    padding: 30px 0 20px;
    /* overflow-y: scroll; */
    flex: 1;
    width: 60rem;
  }

  .column_item {
    
    padding: 10px 0 0px;

  }

  .card_content {
    padding: 40px !important;
  }
  .card_content_traits {
    padding: 40px 40px 0 40px !important;
  }
  .card_content_boosts {
    padding: 0 40px 40px 40px !important;
  }
  .trait_count{
      height: 22px !important;
      width: 50px !important;
      margin-right: 5px;
  }
  .candidatename{
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 36px !important;
    line-height: 150%;
    /* or 54px */
    
    text-align: center;
    letter-spacing: 0.721791px;
  }
  .subtitle_b{
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 18px !important;
    line-height: 24px !important;
    /* identical to box height */
    
    letter-spacing: 0.5px;
    
    color: #223263;
    
  }

  .boost_item{
    width: 348px !important;
    height: 161px !important;
    left: 0px;
    top: 176px;
    border-style: solid;
    border-width: 2px;
    border-radius: 20px;
    border-color: #680EDC;
    margin: 0 3px 3px;
  }