.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.main {
  overflow: hidden;
  display: flex;
  flex: 1;
}

.backButton {
  margin: 8px;
  position: absolute;
}
