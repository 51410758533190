.container {
  display: flex;
  flex-direction: column;
  /* flex: 1; */
  overflow: scroll;
  align-items: flex-start;
}

.panelWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}

.panel {
  padding: 16px;
  max-width: 300px;
  min-width: 200px;
}

.section + .section {
  margin-top: 16px;
}

.header {
  display: flex;
  align-items: center;
}

.progress {
  margin-left: 16px;
}

.backButton {
  margin: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.backIcon {
  font-size: 14px;
}